import type { RouteParams } from 'vue-router'

import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

type QueryParams = RouteParams[keyof RouteParams]
export const valueBuilder = (params: QueryParams[]) => {
  return params
    .filter((value) => !isEmpty(value))
    .map((value) => (Array.isArray(value) ? value[0] : value))
    .join('_')
}

export const sourceTypeForProductsTracking = ({
  routeName,
  pageName,
  blockPosition,
}: {
  routeName: string
  pageName: string
  blockPosition: number
}) => {
  return `${pageName || routeName} ${blockPosition}`
}

export const trackingData = ({
  pageName,
  pageType,
  pageCategory = '',
  blockName = 'UNKNOWN',
}: {
  pageName: string
  pageType: QueryParams
  pageCategory?: QueryParams
  blockName?: string
}): Tracking => ({
  zone: valueBuilder([pageType, pageName, pageCategory]),
  value: blockName,
})
