<template>
  <BlockTitle :subtitle="subtitle" :title="title" />
  <div
    class="flex w-full items-center justify-center gap-16 md:grid-flow-col md:gap-24"
  >
    <CmsButtonBase
      v-if="hasSecondaryCta"
      :rel="secondary?.link?.rel"
      :target="secondary?.link?.target"
      :title="secondary?.label"
      :to="secondary?.link?.href"
      variant="secondary"
      width="fixed"
      @click="() => sendTrackingData(secondary?.label)"
    >
      {{ secondary?.label }}
    </CmsButtonBase>

    <CmsButtonBase
      :rel="primary.link?.rel"
      :target="primary.link?.target"
      :title="primary.label"
      :to="primary.link?.href"
      variant="primary"
      width="fixed"
      @click="() => sendTrackingData(primary.label)"
    >
      {{ primary.label }}
    </CmsButtonBase>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { CTALink } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import CmsButtonBase from '@backmarket/nuxt-layer-cms/CmsButtonBase.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

export type ButtonsProps = {
  primary: CTALink
  secondary?: CTALink
  title?: string
  subtitle?: string
} & ContentBlockProps
const props = defineProps<ButtonsProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { trackClick } = useTracking()
const hasSecondaryCta = computed(() => !isEmpty(props.secondary))

function sendTrackingData(ctaLabel = '') {
  trackClick({
    ...(props.tracking || {}),
    name: ctaLabel,
  })
}
</script>
