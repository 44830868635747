import type {
  CMSPageType,
  ParentPageInfos,
} from '@backmarket/http-api/src/api-specs-content'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'

import type { Crumb } from '../crumb'

type IntermediaryPathParams = {
  pageType: CMSPageType
  parentPageInfos?: ParentPageInfos
}

/* The current page and the parent page always have the same pageType */
export const getIntermediaryPath = ({
  pageType,
  parentPageInfos,
}: IntermediaryPathParams) => {
  if (isEmpty(parentPageInfos)) return []

  const { titleBreadcrumb: title, pageName } = parentPageInfos

  const resolveLocalizedRoute = useRouteLocationWithLocale()

  const crumbs: Crumb[] = [
    {
      title,
      link: resolveLocalizedRoute({
        name: pageType,
        params: {
          pageName,
        },
      }),
    },
  ]

  return crumbs
}
