<template>
  <ul
    class="rounded-lg grid gap-12 p-12"
    :class="reassuranceClasses.containerClass"
  >
    <li v-for="block of mappedBlocks" :key="block.id" class="flex gap-12">
      <div
        class="rounded-md h-40 w-40 p-8"
        :class="reassuranceClasses.iconContainerClass"
      >
        <DynamicIcon
          :class="reassuranceClasses.iconClass"
          :icon="block.icon"
          size="medium"
        />
      </div>
      <div class="text-static-default-hi flex flex-col justify-center">
        <RevLink
          v-if="block.link && block.linkPosition === 'title'"
          class="body-2-link"
          :class="block.titleClass"
          :to="block.link.href"
          >{{ block.title }}</RevLink
        >

        <div v-else class="body-2-bold" :class="block.titleClass">
          {{ block.title }}
        </div>

        <RevLink
          v-if="block.link && block.linkPosition === 'description'"
          class="caption-link"
          :class="block.descriptionClass"
          :to="block.link.href"
          >{{ block.description }}</RevLink
        >

        <div
          v-else-if="!!block.description"
          class="caption"
          :class="block.descriptionClass"
        >
          {{ block.description }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { ReassuranceProps } from '@backmarket/http-api/src/api-specs-content/models/reinsurance'
import { RevLink } from '@ds/components/Link'

import type { ContentBlockProps } from '~/models/content-block'

import DynamicIcon from '../../shared-components/DynamicIcon/DynamicIcon.vue'

import { getClasses, getTextClampingClasses } from './Reassurance.utils'

const props = withDefaults(
  defineProps<ReassuranceProps & ContentBlockProps>(),
  {
    mobileScreenCols: 1,
    hasBackground: false,
    theme: 'gray',
  },
)

const mappedBlocks = computed(() => {
  return props.blocks.map((b) => {
    const { descriptionClass, titleClass } = getTextClampingClasses({
      description: b.description,
      mobileScreenCols: props.mobileScreenCols,
    })

    return {
      ...b,
      titleClass,
      descriptionClass,
    }
  })
})

const reassuranceClasses = computed(() =>
  getClasses({
    theme: props.theme,
    hasBackground: props.hasBackground,
    itemsCount: props.blocks.length,
    mobileScreenCols: props.mobileScreenCols,
  }),
)
</script>
