import { type Component, defineAsyncComponent } from 'vue'

import type { BlockType } from '@backmarket/http-api/src/api-specs-content'

export const COMPONENT_FROM_CONTENT_TYPE: Record<BlockType, Component> = {
  AnimatedProductCheckpoints: defineAsyncComponent(
    () =>
      import(
        '../../blocks/AnimatedProductCheckpoints/AnimatedProductCheckpoints.vue'
      ),
  ),
  Button: defineAsyncComponent(() => import('../../blocks/Button/Button.vue')),
  Buttons: defineAsyncComponent(
    () => import('../../blocks/Buttons/Buttons.vue'),
  ),
  ArticleCardsList: defineAsyncComponent(
    () => import('../../blocks/ArticleCardsList/ArticleCardsList.vue'),
  ),
  // TODO COM-1345 Remove this component
  BuyingGuides: defineAsyncComponent(
    () => import('../../blocks/ArticleCardsList/ArticleCardsList.vue'),
  ),
  Carousel: defineAsyncComponent(
    () => import('../../blocks/Carousel/Carousel.vue'),
  ),
  Categories: defineAsyncComponent(
    () => import('../../blocks/Categories/Categories.vue'),
  ),
  Content: defineAsyncComponent(
    () => import('../../blocks/Content/Content.vue'),
  ),
  ContentMedia: defineAsyncComponent(
    () => import('../../blocks/ContentMedia/ContentMedia.vue'),
  ),
  Countdown: defineAsyncComponent(
    () => import('../../blocks/Countdown/Countdown.vue'),
  ),
  DiscountBanner: defineAsyncComponent(
    () => import('../../blocks/DiscountBanner/DiscountBanner.vue'),
  ),

  EmailCollector: defineAsyncComponent(
    () => import('../../blocks/EmailCollector/EmailCollector.vue'),
  ),
  EmbeddedYoutubeVideo: defineAsyncComponent(
    () => import('../../blocks/EmbeddedYoutubeVideo/EmbeddedYoutubeVideo.vue'),
  ),
  Faq: defineAsyncComponent(() => import('../../blocks/Faq/Faq.vue')),
  HeroBanner: defineAsyncComponent(
    () => import('../../blocks/HeroBanner/HeroBanner.vue'),
  ),
  ListItem: defineAsyncComponent(
    () => import('../../blocks/ListItem/ListItem.vue'),
  ),
  Media: defineAsyncComponent(() => import('../../blocks/Media/Media.vue')),
  ModularCardsCarousel: defineAsyncComponent(
    () => import('../../blocks/ModularCard/ModularCardsCarousel.vue'),
  ),
  PressCarousel: defineAsyncComponent(
    () => import('../../blocks/PressCarousel/PressCarousel.vue'),
  ),
  Products: defineAsyncComponent(
    () => import('../../blocks/Products/Products.vue'),
  ),
  Quiz: defineAsyncComponent(() => import('../../blocks/Quiz/Quiz.vue')),
  Recommendations: defineAsyncComponent(
    () => import('../../blocks/Recommendations/Recommendations.vue'),
  ),
  RefinedProducts: defineAsyncComponent(
    () => import('../../blocks/RefinedProducts/RefinedProducts.vue'),
  ),
  Reinsurance: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Reassurance.vue'),
  ),
  RelatedLinks: defineAsyncComponent(
    () => import('../../blocks/RelatedLinks/RelatedLinks.vue'),
  ),
  RichText: defineAsyncComponent(
    () => import('../../blocks/RichText/RichText.vue'),
  ),
  SectionBanner: defineAsyncComponent(
    () => import('../../blocks/SectionBanner/SectionBanner.vue'),
  ),
  TrustpilotWidget: defineAsyncComponent(
    () => import('../../blocks/TrustpilotWidget/TrustpilotWidget.vue'),
  ),
  VideosBlock: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/VideosBlock.vue'),
  ),
}
