import type { ReassuranceTheme } from '@backmarket/http-api/src/api-specs-content/models/reinsurance'
import { tw } from '@backmarket/utils/string/tw'

export const getGridColsClass = (itemsCount: number) => {
  switch (itemsCount) {
    case 3:
      return 'md:grid-cols-3'

    case 4:
      return 'md:grid-cols-4'

    case 5:
      return 'md:grid-cols-3'

    case 6:
      return 'md:grid-cols-3'

    case 7:
      return 'md:grid-cols-4'

    case 8:
      return 'md:grid-cols-4'

    default:
      return 'md:grid-cols-4'
  }
}

export const getBackgroundClasses = ({
  theme,
  hasBackground,
}: {
  theme: ReassuranceTheme
  hasBackground: boolean
}): { iconContainerClass: string; containerClass: string } => {
  switch (theme) {
    case 'white':
      return {
        containerClass: hasBackground ? 'bg-surface-default-low' : '',
        iconContainerClass: hasBackground
          ? 'bg-surface-default-hi'
          : 'bg-surface-default-low',
      }

    case 'gray':
      return {
        containerClass: hasBackground ? 'bg-surface-default-hi' : '',
        iconContainerClass: hasBackground
          ? 'bg-surface-default-low'
          : 'bg-surface-default-hi',
      }

    case 'yellow':
      return {
        containerClass: hasBackground ? 'bg-static-info-max' : '',
        iconContainerClass: hasBackground
          ? 'bg-surface-default-low'
          : 'bg-static-info-max',
      }

    case 'blue':
      return {
        containerClass: hasBackground ? 'bg-static-info-mid' : '',
        iconContainerClass: hasBackground
          ? 'bg-surface-default-low'
          : 'bg-static-info-mid',
      }

    case 'green':
      return {
        containerClass: hasBackground ? 'bg-static-success-mid' : '',
        iconContainerClass: hasBackground
          ? 'bg-surface-default-low'
          : 'bg-static-success-mid',
      }

    case 'red':
      return {
        containerClass: hasBackground ? 'bg-static-danger-mid' : '',
        iconContainerClass: hasBackground
          ? 'bg-surface-default-low'
          : 'bg-static-danger-mid',
      }

    case 'orange':
      return {
        containerClass: hasBackground ? 'bg-static-warning-mid' : '',
        iconContainerClass: hasBackground
          ? 'bg-surface-default-low'
          : 'bg-static-warning-mid',
      }
    default:
      return {
        containerClass: '',
        iconContainerClass: '',
      }
  }
}

const getIconClass = ({ theme }: { theme: ReassuranceTheme }) => {
  switch (theme) {
    case 'white':
      return tw`text-static-default-hi`

    case 'gray':
      return tw`text-static-default-hi`

    case 'yellow':
      return tw`text-static-default-hi`

    case 'blue':
      return tw`text-static-info-hi`

    case 'green':
      return tw`text-static-success-hi`

    case 'red':
      return tw`text-static-danger-hi`

    case 'orange':
      return tw`text-static-warning-hi`

    default:
      return tw`text-static-default-hi`
  }
}

export const getTextClampingClasses = (props: {
  description?: string
  mobileScreenCols: number
}) => {
  const hideDescription = props.mobileScreenCols === 2

  const description = hideDescription ? '' : props.description

  return {
    titleClass: description ? tw`line-clamp-1` : tw`line-clamp-2`,
    descriptionClass: hideDescription
      ? ['line-clamp-1', tw`hidden`]
      : 'line-clamp-1',
  }
}

export function getClasses({
  theme,
  hasBackground,
  itemsCount,
  mobileScreenCols,
}: {
  theme: ReassuranceTheme
  hasBackground: boolean
  itemsCount: number
  mobileScreenCols: number
}): {
  containerClass: (string | Record<string, boolean>)[]
  iconContainerClass: string
  iconClass: string
} {
  const backgroundClasses = getBackgroundClasses({ theme, hasBackground })
  const gridColsClass = getGridColsClass(itemsCount)
  const iconClass = getIconClass({ theme })

  return {
    containerClass: [
      backgroundClasses.containerClass,
      gridColsClass,
      {
        'grid-cols-1': mobileScreenCols === 1,
        'grid-cols-2': mobileScreenCols === 2,
      },
    ],
    iconContainerClass: backgroundClasses.iconContainerClass,
    iconClass,
  }
}
