<template>
  <div class="bg-static-info-mid px-0 py-32 text-center md:py-56">
    <RevContainer class="mb-32 md:mb-24 md:mt-16">
      <RevIllustration
        alt=""
        class="m-auto block"
        :height="80"
        src="/img/cms/Bomb.svg"
        :width="80"
      />
      <div class="body-1 mb-4 mt-24">
        {{ tagline }}
      </div>
      <div class="heading-1">
        {{ title }}
      </div>
      <div class="body-1 mt-16">
        {{ text }}
      </div>
      <div class="mood-inverse mt-24 flex justify-center">
        <ClientOnly>
          <CountdownDigits
            data-test="countdown-days"
            :digits="days"
            unit="days"
          />
          <CountdownDigits
            data-test="countdown-hours"
            :digits="hours"
            unit="hours"
          />
          <CountdownDigits
            data-test="countdown-minutes"
            :digits="minutes"
            unit="minutes"
          />
        </ClientOnly>
      </div>
    </RevContainer>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

import type { CountdownProps as CountdownPropsModel } from '@backmarket/http-api/src/api-specs-content/models/countdown'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'

import CountdownDigits from './CountdownDigits.vue'
import {
  MINUTE,
  endIsBeforeStart,
  getTimeRemaining,
} from './helpers/date.helpers'
import { useCountdownDigits } from './helpers/useCountdownDigits'

export type CountdownProps = ContentBlockProps & CountdownPropsModel

const now = ref(new Date())
const props = withDefaults(defineProps<CountdownProps>(), {
  title: '',
  tagline: '',
  text: '',
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const endDate = computed(() => new Date(props.date))
const remainingTime = computed(() =>
  getTimeRemaining({ from: now.value, to: endDate.value }),
)

const days = useCountdownDigits(remainingTime, 'days')
const hours = useCountdownDigits(remainingTime, 'hours')
const minutes = useCountdownDigits(remainingTime, 'minutes')

let intervalRef: ReturnType<typeof setInterval>

onMounted(() => {
  intervalRef = setInterval(() => {
    now.value = new Date()

    if (endIsBeforeStart({ start: now.value, end: endDate.value }))
      clearInterval(intervalRef)
  }, MINUTE)
})

onBeforeUnmount(() => clearInterval(intervalRef))
</script>
