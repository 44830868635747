<template>
  <div
    v-if="canBeRendered"
    class="empty:hidden"
    :class="[hiddenOnDeviceClasses, { 'mb-56': properties.withBottomMargin }]"
    :data-qa="dataQaAttribute"
  >
    <component
      :is="block"
      :id="properties.id"
      :block-position="properties.blockPosition"
      v-bind="properties.props"
      :tracking="tracking"
      @error="handleError"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import type {
  BlockType,
  BlockTypeProps,
  Device,
} from '@backmarket/http-api/src/api-specs-content'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { usePageParamsFromRoute } from '../../composables/usePageParamsFromRoute'
import { HIDE_ON_DEVICE_CLASSES } from '../../constants/device-visibility-classes.constants'
import { isHiddenOnAllDevices } from '../../helpers/devices/devices'
import { formatDataQaAttribute } from '../../helpers/qa/formatDataQaAttribute'
import { trackingData } from '../../helpers/tracking/tracking'

import { COMPONENT_FROM_CONTENT_TYPE } from './BlockRenderer.constants'

export type BlockRendererProps = {
  // List of device for which the block is hidden.
  hiddenDevices: Device[]
  // Apply a margin bottom to the block.
  withBottomMargin?: boolean
  // Component name, needs to be found in the index.
  type: BlockType
  // Component properties used to instantiate the component.
  props: BlockTypeProps
} & Pick<ContentBlockProps, 'id' | 'blockPosition'>

defineOptions({ inheritAttrs: false })

const properties = withDefaults(defineProps<BlockRendererProps>(), {
  withBottomMargin: true,
})

const logger = useLogger()
const pageParamsFromRoute = usePageParamsFromRoute()

const hasEmittedAnError = ref(false)

const isAvailable = computed(() => {
  const componentExists = Object.keys(COMPONENT_FROM_CONTENT_TYPE).includes(
    properties.type,
  )

  if (!componentExists) {
    logger.error(
      `The block "${properties.type}" configured in the CMS is not implemented`,
    )
  }

  return componentExists
})

const block = computed(() => COMPONENT_FROM_CONTENT_TYPE[properties.type])

const dataQaAttribute = computed(() => formatDataQaAttribute(properties.type))

const canBeRendered = computed(
  () =>
    !hasEmittedAnError.value &&
    isAvailable.value &&
    !isHiddenOnAllDevices({ devices: properties.hiddenDevices }),
)

const hiddenOnDeviceClasses = computed(() =>
  properties.hiddenDevices.map((device) => HIDE_ON_DEVICE_CLASSES[device]),
)

const tracking = computed(() => {
  const componentsWithDifferentTrackingNeed: BlockType[] = ['Carousel']

  if (componentsWithDifferentTrackingNeed.includes(properties.type)) {
    return undefined
  }

  return trackingData({ ...pageParamsFromRoute, blockName: properties.type })
})

function handleError() {
  hasEmittedAnError.value = true
}
</script>
