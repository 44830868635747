import { type Component, type Ref, computed, ref, watchEffect } from 'vue'

import type { CmsIcon } from '@backmarket/http-api/src/api-specs-content/models/icon'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { RenderDynamicIconState } from '../models/dynamic-icon-state'

/**
 * Dynamic imports have limitations in Rollup which makes it impossible to use import.meta.glob with a node_module package and alias
 * see @doc https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
 *  This object contains all icons currently used in the CMS. We can add more icons when needed
 */
const iconModules = {
  IconAddToCart: () => import('@ds/icons/IconAddToCart'),
  IconAlert: () => import('@ds/icons/IconAlert'),
  IconArchive: () => import('@ds/icons/IconArchive'),
  IconArrowDown: () => import('@ds/icons/IconArrowDown'),
  IconArrowLeft: () => import('@ds/icons/IconArrowLeft'),
  IconArrowRight: () => import('@ds/icons/IconArrowRight'),
  IconArrowUp: () => import('@ds/icons/IconArrowUp'),
  IconAttachment: () => import('@ds/icons/IconAttachment'),
  IconAvatar: () => import('@ds/icons/IconAvatar'),
  IconBackMarket: () => import('@ds/icons/IconBackMarket'),
  IconBackMarketNoBox: () => import('@ds/icons/IconBackMarketNoBox'),
  IconBadge: () => import('@ds/icons/IconBadge'),
  IconBank: () => import('@ds/icons/IconBank'),
  IconBattery: () => import('@ds/icons/IconBattery'),
  IconBellDisabled: () => import('@ds/icons/IconBellDisabled'),
  IconBellFilled: () => import('@ds/icons/IconBellFilled'),
  IconBellOutlined: () => import('@ds/icons/IconBellOutlined'),
  IconBill: () => import('@ds/icons/IconBill'),
  IconBluetooth: () => import('@ds/icons/IconBluetooth'),
  IconBurger: () => import('@ds/icons/IconBurger'),
  IconCalendar: () => import('@ds/icons/IconCalendar'),
  IconCalendarWithCheck: () => import('@ds/icons/IconCalendarWithCheck'),
  IconCamera: () => import('@ds/icons/IconCamera'),
  IconCardView: () => import('@ds/icons/IconCardView'),
  IconChargingWire: () => import('@ds/icons/IconChargingWire'),
  IconChart: () => import('@ds/icons/IconChart'),
  IconCharter: () => import('@ds/icons/IconCharter'),
  IconCheckInCircle: () => import('@ds/icons/IconCheckInCircle'),
  IconCheckInCircleFilled: () => import('@ds/icons/IconCheckInCircleFilled'),
  IconCheckLarge: () => import('@ds/icons/IconCheckLarge'),
  IconCheckSmall: () => import('@ds/icons/IconCheckSmall'),
  IconChevronDown: () => import('@ds/icons/IconChevronDown'),
  IconChevronLeft: () => import('@ds/icons/IconChevronLeft'),
  IconChevronRight: () => import('@ds/icons/IconChevronRight'),
  IconChevronUp: () => import('@ds/icons/IconChevronUp'),
  IconClock: () => import('@ds/icons/IconClock'),
  IconComment: () => import('@ds/icons/IconComment'),
  IconCompass: () => import('@ds/icons/IconCompass'),
  IconComputerMouse: () => import('@ds/icons/IconComputerMouse'),
  IconContact: () => import('@ds/icons/IconContact'),
  IconController: () => import('@ds/icons/IconController'),
  IconCookie: () => import('@ds/icons/IconCookie'),
  IconCopy: () => import('@ds/icons/IconCopy'),
  IconCpu: () => import('@ds/icons/IconCpu'),
  IconCreditCard: () => import('@ds/icons/IconCreditCard'),
  IconCross: () => import('@ds/icons/IconCross'),
  IconCrossInCircle: () => import('@ds/icons/IconCrossInCircle'),
  IconCrosshair: () => import('@ds/icons/IconCrosshair'),
  IconCupcake: () => import('@ds/icons/IconCupcake'),
  IconCurrencyDollar: () => import('@ds/icons/IconCurrencyDollar'),
  IconCurrencyEuro: () => import('@ds/icons/IconCurrencyEuro'),
  IconCurrencyPound: () => import('@ds/icons/IconCurrencyPound'),
  IconCycle: () => import('@ds/icons/IconCycle'),
  IconDiamond: () => import('@ds/icons/IconDiamond'),
  IconDoubleChevronUp: () => import('@ds/icons/IconDoubleChevronUp'),
  IconDownload: () => import('@ds/icons/IconDownload'),
  IconEdit: () => import('@ds/icons/IconEdit'),
  IconEqualizer: () => import('@ds/icons/IconEqualizer'),
  IconExpand: () => import('@ds/icons/IconExpand'),
  IconExport: () => import('@ds/icons/IconExport'),
  IconEyeClosed: () => import('@ds/icons/IconEyeClosed'),
  IconEyeOpen: () => import('@ds/icons/IconEyeOpen'),
  IconEyes: () => import('@ds/icons/IconEyes'),
  IconFactory: () => import('@ds/icons/IconFactory'),
  IconFile: () => import('@ds/icons/IconFile'),
  IconFlag: () => import('@ds/icons/IconFlag'),
  IconFloat: () => import('@ds/icons/IconFloat'),
  IconGear: () => import('@ds/icons/IconGear'),
  IconGift: () => import('@ds/icons/IconGift'),
  IconGlasses: () => import('@ds/icons/IconGlasses'),
  IconGpu: () => import('@ds/icons/IconGpu'),
  IconGrowth: () => import('@ds/icons/IconGrowth'),
  IconHdmi: () => import('@ds/icons/IconHdmi'),
  IconHeadset: () => import('@ds/icons/IconHeadset'),
  IconHeart: () => import('@ds/icons/IconHeart'),
  IconHeartBroken: () => import('@ds/icons/IconHeartBroken'),
  IconHeartDiagnostic: () => import('@ds/icons/IconHeartDiagnostic'),
  IconHeartExploded: () => import('@ds/icons/IconHeartExploded'),
  IconHeartFilled: () => import('@ds/icons/IconHeartFilled'),
  IconHome: () => import('@ds/icons/IconHome'),
  IconImage: () => import('@ds/icons/IconImage'),
  IconInfo: () => import('@ds/icons/IconInfo'),
  IconKeyboard: () => import('@ds/icons/IconKeyboard'),
  IconLeaf: () => import('@ds/icons/IconLeaf'),
  IconLightbulb: () => import('@ds/icons/IconLightbulb'),
  IconListView1: () => import('@ds/icons/IconListView1'),
  IconListView2: () => import('@ds/icons/IconListView2'),
  IconLocationPinFilled: () => import('@ds/icons/IconLocationPinFilled'),
  IconLocationPinOutlined: () => import('@ds/icons/IconLocationPinOutlined'),
  IconLockLocked: () => import('@ds/icons/IconLockLocked'),
  IconLockUnlocked: () => import('@ds/icons/IconLockUnlocked'),
  IconLogOut: () => import('@ds/icons/IconLogOut'),
  IconMail: () => import('@ds/icons/IconMail'),
  IconMedal: () => import('@ds/icons/IconMedal'),
  IconMegaphone: () => import('@ds/icons/IconMegaphone'),
  IconMinus: () => import('@ds/icons/IconMinus'),
  IconMinusInCircle: () => import('@ds/icons/IconMinusInCircle'),
  IconMoney: () => import('@ds/icons/IconMoney'),
  IconMore: () => import('@ds/icons/IconMore'),
  IconMove: () => import('@ds/icons/IconMove'),
  IconOfficial: () => import('@ds/icons/IconOfficial'),
  IconPackage: () => import('@ds/icons/IconPackage'),
  IconPeople: () => import('@ds/icons/IconPeople'),
  IconPlug: () => import('@ds/icons/IconPlug'),
  IconPlus: () => import('@ds/icons/IconPlus'),
  IconPlusInCircle: () => import('@ds/icons/IconPlusInCircle'),
  IconPower: () => import('@ds/icons/IconPower'),
  IconPowerAdapterApple: () => import('@ds/icons/IconPowerAdapterApple'),
  IconPriceTag: () => import('@ds/icons/IconPriceTag'),
  IconPriceUpdate: () => import('@ds/icons/IconPriceUpdate'),
  IconPrint: () => import('@ds/icons/IconPrint'),
  IconPromo: () => import('@ds/icons/IconPromo'),
  IconQuality: () => import('@ds/icons/IconQuality'),
  IconReassuranceArrowFilled: () =>
    import('@ds/icons/IconReassuranceArrowFilled'),
  IconReassuranceArrowOutlined: () =>
    import('@ds/icons/IconReassuranceArrowOutlined'),
  IconReassuranceCreditCardFilled: () =>
    import('@ds/icons/IconReassuranceCreditCardFilled'),
  IconReassuranceCreditCardOutlined: () =>
    import('@ds/icons/IconReassuranceCreditCardOutlined'),
  IconReassuranceGlobeFilled: () =>
    import('@ds/icons/IconReassuranceGlobeFilled'),
  IconReassuranceGlobeOutlined: () =>
    import('@ds/icons/IconReassuranceGlobeOutlined'),
  IconReassuranceLockFilled: () =>
    import('@ds/icons/IconReassuranceLockFilled'),
  IconReassuranceLockOutlined: () =>
    import('@ds/icons/IconReassuranceLockOutlined'),
  IconReassuranceParcelFilled: () =>
    import('@ds/icons/IconReassuranceParcelFilled'),
  IconReassuranceParcelOutlined: () =>
    import('@ds/icons/IconReassuranceParcelOutlined'),
  IconReassuranceShieldFilled: () =>
    import('@ds/icons/IconReassuranceShieldFilled'),
  IconReassuranceShieldOutlined: () =>
    import('@ds/icons/IconReassuranceShieldOutlined'),
  IconReassuranceSmileyFilled: () =>
    import('@ds/icons/IconReassuranceSmileyFilled'),
  IconReassuranceSmileyOutlined: () =>
    import('@ds/icons/IconReassuranceSmileyOutlined'),
  IconReassuranceTruckFilled: () =>
    import('@ds/icons/IconReassuranceTruckFilled'),
  IconReassuranceTruckOutlined: () =>
    import('@ds/icons/IconReassuranceTruckOutlined'),
  IconRecommendation: () => import('@ds/icons/IconRecommendation'),
  IconRefresh: () => import('@ds/icons/IconRefresh'),
  IconRefund: () => import('@ds/icons/IconRefund'),
  IconRepairHome: () => import('@ds/icons/IconRepairHome'),
  IconRepairMailIn: () => import('@ds/icons/IconRepairMailIn'),
  IconRepairSelf: () => import('@ds/icons/IconRepairSelf'),
  IconRepairStore: () => import('@ds/icons/IconRepairStore'),
  IconScratches: () => import('@ds/icons/IconScratches'),
  IconSearch: () => import('@ds/icons/IconSearch'),
  IconSend: () => import('@ds/icons/IconSend'),
  IconShare: () => import('@ds/icons/IconShare'),
  IconShareAndroid: () => import('@ds/icons/IconShareAndroid'),
  IconShop: () => import('@ds/icons/IconShop'),
  IconShoppingBag: () => import('@ds/icons/IconShoppingBag'),
  IconSim: () => import('@ds/icons/IconSim'),
  IconSiren: () => import('@ds/icons/IconSiren'),
  IconSmartphone: () => import('@ds/icons/IconSmartphone'),
  IconSmartphoneBroken: () => import('@ds/icons/IconSmartphoneBroken'),
  IconSmartphoneCamera: () => import('@ds/icons/IconSmartphoneCamera'),
  IconSmartphoneScreenSize: () => import('@ds/icons/IconSmartphoneScreenSize'),
  IconSort: () => import('@ds/icons/IconSort'),
  IconSos: () => import('@ds/icons/IconSos'),
  IconSoundLow: () => import('@ds/icons/IconSoundLow'),
  IconSoundOff: () => import('@ds/icons/IconSoundOff'),
  IconSoundOn: () => import('@ds/icons/IconSoundOn'),
  IconSparkles: () => import('@ds/icons/IconSparkles'),
  IconSpinner: () => import('@ds/icons/IconSpinner'),
  IconStarFilled: () => import('@ds/icons/IconStarFilled'),
  IconStarHalf: () => import('@ds/icons/IconStarHalf'),
  IconStarOutlined: () => import('@ds/icons/IconStarOutlined'),
  IconSunBeam: () => import('@ds/icons/IconSunBeam'),
  IconSunFace: () => import('@ds/icons/IconSunFace'),
  IconSwap: () => import('@ds/icons/IconSwap'),
  IconThumbsDownFilled: () => import('@ds/icons/IconThumbsDownFilled'),
  IconThumbsDownOutlined: () => import('@ds/icons/IconThumbsDownOutlined'),
  IconThumbsUpFilled: () => import('@ds/icons/IconThumbsUpFilled'),
  IconThumbsUpOutlined: () => import('@ds/icons/IconThumbsUpOutlined'),
  IconTools: () => import('@ds/icons/IconTools'),
  IconTouch: () => import('@ds/icons/IconTouch'),
  IconTrash: () => import('@ds/icons/IconTrash'),
  IconTrophy: () => import('@ds/icons/IconTrophy'),
  IconTruck: () => import('@ds/icons/IconTruck'),
  IconTruckExpress: () => import('@ds/icons/IconTruckExpress'),
  IconTv: () => import('@ds/icons/IconTv'),
  IconUndo: () => import('@ds/icons/IconUndo'),
  IconUnlock: () => import('@ds/icons/IconUnlock'),
  IconUpload: () => import('@ds/icons/IconUpload'),
  IconWarning: () => import('@ds/icons/IconWarning'),
  IconWaterdrop: () => import('@ds/icons/IconWaterdrop'),
  IconWifi: () => import('@ds/icons/IconWifi'),
} satisfies Record<CmsIcon['icon'], () => Component>

function keyExists(icon: string): icon is keyof typeof iconModules {
  return Object.keys(iconModules).includes(icon)
}

async function importIconComponent(icon: string): Promise<Component> {
  if (!keyExists(icon)) {
    throw new Error(`[CMS Icon] Could not find path to import icon ${icon}`)
  }

  const importIconFunction = iconModules[icon]
  const iconModule = (await importIconFunction()) as {
    [icon: string]: Component
  }
  const iconComponent = iconModule[icon]

  return iconComponent
}

export function useImportIconComponent(iconName: Ref<string>) {
  const logger = useLogger()

  const icon = ref<Component | null>(null)
  const renderIconState = ref<RenderDynamicIconState>(
    RenderDynamicIconState.Pending,
  )

  const success = computed(
    () => renderIconState.value === RenderDynamicIconState.Success,
  )

  const pending = computed(
    () => renderIconState.value === RenderDynamicIconState.Pending,
  )

  watchEffect(async () => {
    try {
      const iconComponent = await importIconComponent(iconName.value)
      icon.value = iconComponent
      renderIconState.value = RenderDynamicIconState.Success
    } catch (err) {
      logger.error(
        `[CMS Icon] icon ${
          iconName.value
        } does not exist in the Design System. Error ${
          (err as Error)?.message
        }`,
      )
      renderIconState.value = RenderDynamicIconState.Error
    }
  })

  return { icon, success, pending }
}
