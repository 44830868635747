<template>
  <CmsButtonBase
    v-if="displayCta"
    :rel="ctaLink?.link?.rel"
    :target="ctaLink?.link?.target"
    :to="ctaLink?.link?.href"
    variant="secondary"
    :width="width"
    @click="sendTrackingData"
  >
    {{ ctaLink?.label }}
  </CmsButtonBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { CTALink } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import CmsButtonBase from '@backmarket/nuxt-layer-cms/CmsButtonBase.vue'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

const props = defineProps<{
  ctaLink?: CTALink
  tracking?: Tracking
  width?: 'fixed' | 'adaptive' | 'full-width'
}>()

const { trackClick } = useTracking()

const displayCta = computed(() => !isEmpty(props.ctaLink))

function sendTrackingData() {
  if (isEmpty(props.tracking)) return
  trackClick(props.tracking)
}
</script>
